<template>
  <b-card title="User Timeline">
    <app-timeline>
      <app-timeline-item
        variant="info"
        :title="$t('action.edit')"
        :subtitle="$t('action.edit')"
        :time="$t('common.today')"
      />
    </app-timeline>
  </b-card>
</template>

<script>
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

export default {
  name: 'UserDetailsTimelineCard',

  components: {
    AppTimeline,
    AppTimelineItem,
  },
}
</script>
