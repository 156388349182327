<template>
  <div v-if="organizationUser">
    <!-- Alert: No item found -->
    <b-alert v-if="isLoaded" variant="danger">
      <h4 class="alert-heading">{{ $t('alert.user.no_user_found') }}</h4>
    </b-alert>

    <!-- First Row -->
    <b-row>
      <b-col cols="12">
        <UserDetailsInfoCard :user-data="organizationUser" />
      </b-col>

      <!-- TODO -->
      <b-col v-if="false" cols="12" lg="5">
        <UserDetailsTimelineCard />
      </b-col>

      <b-col cols="12">
        <rights-table
          :is-me="$route.params.organizationUser_id === selectedOrganization.userId"
          :grantable-rights="grantableRights"
          :user-data="organizationUser"
          :editable-name="false"
          :editable-rights="false"
          :display-save-button="false"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import RightsTable from '@/components/RightsTable.vue'
import { fetchOrganizationUserRequest } from '@/request/globalApi/requests/organizationRequests'
import UserDetailsInfoCard from './UserDetailsInfoCard.vue'
import UserDetailsTimelineCard from './UserDetailsTimelineCard.vue'

export default {
  name: 'UserDetails',

  components: {
    RightsTable,
    // Local Components
    UserDetailsInfoCard,
    UserDetailsTimelineCard,
  },
  data() {
    return {
      isLoaded: false,
      organizationUser: undefined,
      workroleToShow: null,
      displayRightsTable: true,
      rights: null,
    }
  },
  computed: {
    ...mapState('auth', ['selectedOrganization', 'user']),
    grantableRights() {
      if (this.selectedOrganization) return this.selectedOrganization.rights.map(right => right.action)
      return []
    },
  },
  mounted() {
    this.APIfetchOrganizationUser()
  },
  methods: {
    APIfetchOrganizationUser() {
      fetchOrganizationUserRequest(this.selectedOrganization.id, this.$route.params.organizationUser_id)
        .then(response => {
          this.organizationUser = response.data.organizationUser
          this.isLoaded = true
        })
    },
  },
}
</script>
